// app/javascript/controllers/search_query_controller.js
import { Controller } from "@hotwired/stimulus"
import debounce from "debounce"

export default class extends Controller {
  static targets = ["searchInput", "spinner"]

  connect() {
    this.submitDebounced = debounce(this.submit.bind(this), 1000)

    // Handle loading state for Turbo
    document.addEventListener("turbo:frame-load", () => {
      if (this.hasSpinnerTarget) {
        this.spinnerTarget.classList.add("d-none")
      }
    })

    // Initialize URL handling
    const currentUrl = new URL(window.location.href)
    const searchParams = new URLSearchParams(currentUrl.search).get("search")
    if (searchParams === "") {
      currentUrl.searchParams.delete("search")
      window.history.replaceState({}, document.title, currentUrl.toString())
    }
  }

  disconnect() {
    this.searchInputTarget.removeEventListener("search", this.submitDebounced)
    this.searchInputTarget.removeEventListener("click", this.submitDebounced)
    document.removeEventListener("turbo:frame-load", this.hideSpinner)
  }

  initialize() {
    const submitBtn = document.querySelectorAll("button[type=submit]")[0]
    if (submitBtn) {
      submitBtn.addEventListener("click", (e) => {
        e.preventDefault()
        this.submit(e)
      })
    }

    this.searchInputTarget.addEventListener("search", (e) => {
      const inputValue = this.searchInputTarget.value.trim()
      const currentUrl = new URL(window.location.href)

      if (inputValue === "") {
        currentUrl.searchParams.delete("search")
        window.history.replaceState({}, document.title, currentUrl.toString())
      }

      this.submit(e)
    })

    // Add input event listener for real-time search
    this.searchInputTarget.addEventListener("input", (e) => {
      if (this.hasSpinnerTarget) {
        this.spinnerTarget.classList.remove("d-none")
      }
      this.submitDebounced(e)
    })
  }

  submit(event) {
    event.preventDefault()

    // Update URL with search parameter
    const currentUrl = new URL(window.location.href)
    const searchValue = this.searchInputTarget.value.trim()

    if (searchValue) {
      currentUrl.searchParams.set("search", searchValue)
    } else {
      currentUrl.searchParams.delete("search")
    }

    window.history.replaceState({}, document.title, currentUrl.toString())

    // Show spinner if it exists
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove("d-none")
    }

    this.element.submit()
  }

  hideSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add("d-none")
    }
  }
}
