// app/javascript/controllers/toast_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Show toast
    this.element.classList.add("fadein", "show")
    this.element.classList.remove("hide")

    // Set timeout to hide toast
    this.timeout = setTimeout(() => {
      this.hideToast()
    }, parseInt(this.element.dataset.bsDelay) || 1000)
  }

  disconnect() {
    // Clear timeout if element is removed
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  hideToast() {
    this.element.classList.add("hide")
    this.element.classList.remove("show")

    // Remove element after animation
    setTimeout(() => {
      this.element.remove()
    }, 300) // Adjust timing to match your fade animation duration
  }
}
