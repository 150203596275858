// app/javascript/controllers/bulk_message_csv_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["messageInput", "submitButton"]

  connect() {
    this.toggleMessageInput()
    this.validateForm()
  }

  toggleMessageInput() {
    const singleMessageSelected = document.querySelector(
      'input[name="message_type"][value="single"]'
    ).checked
    const messageInput = document.getElementById("single-message-input")
    const textarea = messageInput.querySelector("textarea")

    if (singleMessageSelected) {
      messageInput.classList.remove("d-none")
      textarea.required = true
    } else {
      messageInput.classList.add("d-none")
      textarea.required = false
      textarea.value = "" // Clear the textarea when hidden
    }

    this.validateForm()
  }

  validateForm() {
    const form = this.element
    const submitButton = form.querySelector('input[type="submit"]')
    const singleMessageSelected = document.querySelector(
      'input[name="message_type"][value="single"]'
    ).checked
    const messageContent = document.querySelector('textarea[name="message_content"]')
    const csvFile = document.querySelector('input[type="file"]')

    let isValid = csvFile.files.length > 0
    if (singleMessageSelected) {
      isValid = isValid && messageContent.value.trim().length > 0
    }

    submitButton.disabled = !isValid
  }
}
