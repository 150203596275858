// app/javascript/controllers/bulk_message_controller.js
import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["recipientSelect", "selectedIds", "recipientTypeRadio"]

  connect() {
    this.initializeTomSelect()
    // Initial load of recipients
    this.toggleRecipientType()
  }

  initializeTomSelect() {
    if (this.tomSelect) {
      this.tomSelect.destroy()
    }

    this.tomSelect = new TomSelect(this.recipientSelectTarget, {
      valueField: "id",
      labelField: "text",
      searchField: ["text"],
      plugins: ["remove_button"],
      maxItems: null,
      onItemAdd: () => this.updateSelectedIds(),
      onItemRemove: () => this.updateSelectedIds(),
      render: {
        option: function (item) {
          return `<div class="py-2 px-3">${item.text}</div>`
        },
      },
    })
  }

  async toggleRecipientType(event) {
    const selectedType = this.recipientTypeRadioTargets.find(
      (radio) => radio.checked
    ).value
    const endpoint = `/administration/conversations/${
      selectedType === "student" ? "get_students" : "get_tutors"
    }`

    try {
      const response = await fetch(endpoint, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()

      this.tomSelect.clear()
      this.tomSelect.clearOptions()
      this.tomSelect.addOptions(data)

      this.selectedIdsTarget.value = ""
    } catch (error) {
      console.error("Error fetching recipients:", error)
      // You might want to show an error message to the user here
    }
  }

  updateSelectedIds() {
    const selectedItems = this.tomSelect.items
    this.selectedIdsTarget.value = selectedItems.join(",")
  }

  disconnect() {
    if (this.tomSelect) {
      this.tomSelect.destroy()
    }
  }
}
